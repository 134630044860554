import {relation, date, field, text} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class Authorization extends Base {
  static table = 'authorizations';
  static associations = {
    patients: {type: 'belongs_to', key: 'patient_id'},
    payers: {type: 'belongs_to', key: 'payer_id'},
    progress_reports: {type: 'belongs_to', key: 'progress_report_id'},
  };

  @relation('patients', 'patient_id') patient;
  @relation('payers', 'payer_id') payer;

  @date('start_date') startDate;
  @date('end_date') endDate;
  @text('authorization_number') authorizationNumber;
  @field('assessment') assessment;
  @field('supporting_assessment') supportingAssessment;
  @field('assessment_multi_tech') assessmentMultiTech;
  @field('direct_therapy') directTherapy;
  @field('direct_group_therapy') directGroupTherapy;
  @field('supervision') supervision;
  @field('supervision_multi_tech') supervisionMultiTech;
  @field('caregiver_guidance') caregiverGuidance;
  @field('caregiver_guidance_group') caregiverGuidanceGroup;
  @field('payer_id') payerId;
  @field('patient_id') patientId;
  @text('state') state;
  @field('cpt97151') cpt97151;
  @field('cpt97152') cpt97152;
  @field('cpt97153') cpt97153;
  @field('cpt97154') cpt97154;
  @field('cpt97155') cpt97155;
  @field('cpt97156') cpt97156;
  @field('cpt97157') cpt97157;
  @field('cpt97158') cpt97158;
  @field('cpt0373T') cpt0373T;
  @field('cpt0362T') cpt0362T;
  @field('cptT1024') cptT1024;
  @field('cpt92507') cpt92507;
  @field('cpt92508') cpt92508;
  @field('cpt92526') cpt92526;
  @field('cpt92609') cpt92609;
  @field('cpt92522') cpt92522;
  @field('cpt92523') cpt92523;
  @field('cpt92610') cpt92610;
  @field('cptH2019') cptH2019;
  @field('cptT2024') cptT2024;
  @field('cptH0032') cptH0032;
  @field('cpt90791') cpt90791;
  @field('cpt90837') cpt90837;
  @field('cptH0031') cptH0031;
  @field('cptH2012') cptH2012;
  @field('cptS5111') cptS5111;
  @field('cptH2014') cptH2014;
  @field('cptH0046') cptH0046;
  @field('cpt612') cpt612;
  @field('cpt616') cpt616;
  @field('cpt805') cpt805;
}
