import React, {useEffect, useRef, useState} from 'react';
import {
  FlatList,
  View,
  ScrollView,
  TouchableOpacity,
  Platform,
} from 'react-native';
import {Behavior, Skill} from 'src/modules/session/components';
import NoResults from 'src/common-components/noResults';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import withState from 'src/redux/wrapper';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import BehaviorSeparator from '../behavior-separator';
import {Typography} from 'src/styles';
import {Text} from 'react-native';
import {NoteTemplateVersion, SessionProgram} from 'src/models';
import {useDatabase} from '@nozbe/watermelondb/hooks';

const SessionCollect = ({
  appointment,
  session,
  sortedBehaviors,
  sortedSkills,
}: any) => {
  const skillRef = useRef();
  const behaviorRef = useRef();
  const database = useDatabase();
  const styles = useStyle();
  const translations = useTranslations();
  const [behaviors, setBehaviors] = useState(sortedBehaviors);
  const [skills, setSkills] = useState(sortedSkills);

  const compareNoteTemplates = async () => {
    const latestNoteTemplateVersions = await database
      .get(NoteTemplateVersion.table)
      .query(
        Q.where('note_template_id', session.noteTemplateId),
        Q.sortBy('published_at', 'desc'),
        Q.take(1),
      );
    const latestNoteTemplateVersion = latestNoteTemplateVersions?.[0];
    const sessionNoteTemplateVersion = await database
      .get(NoteTemplateVersion.table)
      .find(session.noteTemplateVersionId);
    if (appointment.noteTemplateId !== session.noteTemplateId) {
      const appointmentNoteTemplateVersions = await database
        .get(NoteTemplateVersion.table)
        .query(
          Q.where('note_template_id', appointment.noteTemplateId),
          Q.sortBy('published_at', 'desc'),
          Q.take(1),
        );
      const appointmentNoteTemplateVersion = appointmentNoteTemplateVersions[0];
      await session.updateEntity({
        noteTemplateId: appointment.noteTemplateId,
        noteTemplateVersionId: appointmentNoteTemplateVersion.id,
        note: session.note.replace(
          sessionNoteTemplateVersion.template,
          appointmentNoteTemplateVersion.template,
        ),
      });
    } else if (
      sessionNoteTemplateVersion &&
      latestNoteTemplateVersion &&
      sessionNoteTemplateVersion?.id !== latestNoteTemplateVersion?.id
    ) {
      await session.updateEntity({
        note: session.note.replace(
          sessionNoteTemplateVersion.template,
          latestNoteTemplateVersion.template,
        ),
        noteTemplateVersionId: latestNoteTemplateVersion?.id,
      });
    }
  };

  useEffect(() => {
    const filteredBehaviors = sortedBehaviors.filter(
      (program: any, index: number) => {
        return (
          index ===
          sortedBehaviors.findIndex(obj => program.programId === obj.programId)
        );
      },
    );

    const filteredSkills = sortedSkills.filter(
      (program: any, index: number) => {
        return (
          index ===
          sortedSkills.findIndex(obj => program.programId === obj.programId)
        );
      },
    );
    setBehaviors(filteredBehaviors);
    setSkills(filteredSkills);
  }, [sortedBehaviors, sortedSkills]);

  useEffect(() => {
    compareNoteTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startClk = () => {
    session.updateEntity({
      startTimestamp: new Date(),
    });
  };

  return (
    <View style={[styles.flex]}>
      <View
        style={[
          styles.height200,
          styles.backgroundColorBlack,
          styles.borderTop,
          styles.borderBottomLeftRadius,
          styles.borderBottomRightRadius,
        ]}
      />
      {skills.length !== 0 ? (
        <FlatList
          data={skills}
          keyExtractor={item => item?.id}
          renderItem={({item: program, index: idx}) => (
            <Skill sessionProgram={program} session={session} index={idx} />
          )}
          onScrollToIndexFailed={() => {}}
          initialNumToRender={1}
          contentContainerStyle={styles.paddingVertical}
          ref={ref => {
            skillRef.current = ref;
          }}
        />
      ) : (
        <NoResults message={translations('no_skills')} />
      )}
      <ScrollView
        style={[
          styles.paddingHorizontal,
          styles.paddingTop,
          styles.positionAbsolute,
          styles.width,
          styles.zIndex,
        ]}>
        <FlatList
          horizontal
          showsHorizontalScrollIndicator={true}
          data={behaviors}
          keyExtractor={item => item?.id?.toString()}
          renderItem={({item: program}) => (
            <Behavior sessionProgram={program} session={session} />
          )}
          ItemSeparatorComponent={BehaviorSeparator}
          ListFooterComponentStyle={[styles.marginLeft]}
          onScrollToIndexFailed={() => {}}
          ref={ref => {
            behaviorRef.current = ref;
          }}
        />
      </ScrollView>
      {session?.startTimestamp === null ? (
        <View
          style={[
            styles.positionAbsolute,
            styles.top0,
            styles.bottom0,
            styles.left0,
            styles.right0,
            styles.alignCenter,
            styles.backgroundColorOverlay,
            styles.zIndex,
          ]}>
          <TouchableOpacity
            style={[
              styles.width150,
              styles.paddingLVertical,
              styles.backgroundColorWhite,
              styles.borderRadius50,
              styles.alignCenter,
              // eslint-disable-next-line react-native/no-inline-styles
              {marginTop: Platform.OS === 'web' ? '25%' : '50%'},
            ]}
            onPress={startClk}>
            <Text
              style={[Typography.SECONDARY_BUTTON, styles.textColorPrimary]}>
              {translations('start')}
            </Text>
          </TouchableOpacity>
        </View>
      ) : (
        <></>
      )}
    </View>
  );
};

export default compose(
  withState,
  withDatabase,
  withObservables([], ({database, behaviors, skills, session}: any) => {
    const behavior_id = behaviors.map((behavior: any) => behavior.id);
    const skill_id = skills.map((skill: any) => skill.id);
    return {
      session,
      sortedBehaviors: database
        .get(SessionProgram.table)
        .query(
          Q.where('session_id', session.id),
          Q.on(
            'programs',
            Q.and(
              Q.where('id', Q.oneOf(behavior_id)),
              Q.where('state', Q.notEq('archived')),
            ),
          ),
          Q.where('enabled', true),
          Q.sortBy('rank', Q.asc),
        ),
      sortedSkills: database
        .get(SessionProgram.table)
        .query(
          Q.where('session_id', session.id),
          Q.on(
            'programs',
            Q.and(
              Q.where('id', Q.oneOf(skill_id)),
              Q.where('state', Q.notEq('archived')),
            ),
          ),
          Q.where('enabled', true),
          Q.sortBy('rank', Q.asc),
        ),
    };
  }),
)(SessionCollect);
