import React from 'react';
import {DataItem, SelectInput, ToggleButtonInput} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';

interface DisplayProps {
  value: string;
}

interface Props {
  valueKey?: string;
  name?: string;
}
// [
//   {value: '252 Assessment', title: '97151 - Assessment'},
//   {value: '461 Therapy', title: '97153 - Direct Therapy'},
//   {
//     value: '509 Parenting/Family Support/Education',
//     title: '97156 - Caregiver Guidance',
//   },
//   {value: '565', title: '97158 - Supervision Group'},
//   {value: '279 Case Management', title: 'Indirect Therapy'},
//   {value: '625 Plan Management', title: 'NB - Non-Billable'},
// ]

export const appointmentTypes = [
  {
    label: '97151 - Assessment',
    value: '252 Assessment',
  },
  {
    label: '97151 - Indirect Assessment',
    value: '279 Case Management',
  },
  {
    label: '97152 - Supporting Assessment',
    value: '97152 - Supporting Assessment',
  },
  {
    label: '97153 - Direct Therapy',
    value: '461 Therapy',
  },
  {
    label: '97154 - Group intervention',
    value: '97154 - Group intervention',
  },
  {
    label: '97155 - Supervision/Tx Modification',
    value: 'Supervision',
  },
  {
    label: '97156 - Caregiver Guidance',
    value: '509 Parenting/Family Support/Education',
  },
  {
    label: '97157 - Group Caregiver Guidance',
    value: '97157 - Group Caregiver Guidance',
  },
  {
    value:
      '97158 - Group adaptive behavior treatment with protocol modification',
    label:
      '97158 - Group adaptive behavior treatment with protocol modification',
  },
  {
    label: '0373T - Multi Staff',
    value: '0373T - Multi Staff',
  },
  {
    label: '0362T - Multi Staff Assessment',
    value: '0362T - Multi Staff Assessment',
  },
  {
    label: 'T1024  - Team Evaluation & Management',
    value: 'T1024  - Team Evaluation & Management',
  },
  {
    label: 'Non-Billable',
    value: '625 Plan Management',
  },
  {
    label: '92507 - Basic Speech Treatment',
    value: '92507 - Basic Speech Treatment',
  },
  {
    label: '92508 - Basic Speech Treatment (Group)',
    value: '92508 - Basic Speech Treatment (Group)',
  },
  {
    label: '92526 - Feeding Tx / Swallowing Function',
    value: '92526 - Feeding Tx / Swallowing Function',
  },
  {
    label: '92609 - AAC Modification',
    value: '92609 - AAC Modification',
  },
  {
    label: '92522 - Speech Sound Production Evaluation',
    value: '92522 - Speech Sound Production Evaluation',
  },
  {
    label: '92523 - Expressive and Receptive Language Evaluation',
    value: '92523 - Expressive and Receptive Language Evaluation',
  },
  {
    label: '92610 - Feeding - Oral & Pharyngeal Evaluation',
    value: '92610 - Feeding - Oral & Pharyngeal Evaluation',
  },
  {value: 'T2024', label: 'T2024 - Assessment/plan of care development'},
  {value: 'H2019', label: 'H2019 - Therapeutic behavioral services'},
  {
    value: 'H0032',
    label: 'H0032 - Mental health service plan development by non-physician',
  },
  {value: '90791', label: '90791 - Psych diagnostic evaluation'},
  {value: '90837', label: '90837 - Psytx w pt 60 minutes'},
  {value: 'H0031', label: 'H0031 - Mental health assessment, by non-physician'},
  {value: 'H2012', label: 'H2012 - Behavioral health day treatment, per hour'},
  {value: 'S5111', label: 'S5111 - Home care training, family; per session'},
  {
    value: 'H2014',
    label: 'H2014 - Skills training and development, per 15 minutes',
  },
  {
    value: 'H0046',
    label: 'H0046 - Mental health services, not otherwise specified',
  },
  {
    value: '612',
    label:
      '612 - Behavior Management Assistant (BMA) (Direct therapy by a technician)',
  },
  {
    value: '616',
    label: '616 - Specialized Behavior Management Services',
  },
  {
    value: '805',
    label: '805 - Infant Development Program (IDP)',
  },
];

export const Import = (
  key: string = 'appointmentType',
  prefix?: string,
): IDeveloperField => ({
  label:
    (prefix ? prefix + ' ' : '') +
    `Appointment Type (${appointmentTypes
      .map(value => value.label)
      .join(', ')})`,
  key,
  type: 'select',
  selectOptions: appointmentTypes,
  validators: [
    {
      validate: 'required',
    },
  ],
});

const typeFilters = [
  {
    label: 'All',
    value: 'ALL',
  },
  ...appointmentTypes,
];

const resolveDisplayValue = (value: string) => {
  return appointmentTypes.find(item => item.value === value)?.label;
};

const TypeDisplay = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Session Type</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const TypeInput = ({valueKey, name}: Props) => {
  return (
    <SelectInput
      valueKey={(valueKey ? valueKey + '.' : '') + 'type'}
      name={name ? name : 'Appointment Type'}
      required={true}
      items={appointmentTypes}
    />
  );
};

const TypeFilter = ({valueKey}: Props) => {
  return (
    <ToggleButtonInput
      valueKey={(valueKey ? valueKey + '.' : '') + 'type'}
      name={'Appointment Type'}
      required={true}
      buttons={typeFilters}
    />
  );
};

export const validation = () =>
  Yup.string().required('Appointment Type is required');

export const Display = TypeDisplay;
export const Input = TypeInput;
export const Filter = TypeFilter;
export const Value = resolveDisplayValue;

export default TypeInput;
