import React, {useEffect} from 'react';
import {Text, View} from 'react-native';
import {RHAvatar} from 'src/common-components/custom-ui-helpers';
import {Colors, Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import {User} from 'src/models';
import {TouchableOpacity} from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {InputGroup} from 'src/design-system';
import StartTime from 'src/hook-form-inputs/start-time';
import EndTime from 'src/hook-form-inputs/end-time';
import Billable from 'src/hook-form-inputs/billable';
import Supervision from 'src/hook-form-inputs/supervision';
import ListItemSeparator from 'src/common-components/separator';
import Cpt from 'src/hook-form-inputs/cpt';
import {useFormContext, useWatch} from 'react-hook-form';

const UserListItem = ({user, index, showTime, onClose}: any) => {
  const styles = useStyle();

  let firstLineDisplay = '';
  const firstName = user?.firstName || '';
  const lastName = user?.lastName || '';
  firstLineDisplay = firstName + ' ' + lastName;
  const firstInitial = firstName.length > 0 ? firstName[0].toUpperCase() : 'N';
  const lastInitial = lastName.length > 0 ? lastName[0].toUpperCase() : 'N';
  const avatar = firstInitial + lastInitial;

  const {control, setValue} = useFormContext();

  const watchedSupervision = useWatch({
    control,
    name: `selectedStaff.${index}.supervision`,
  });

  const watchedBillable = useWatch({
    control,
    name: `selectedStaff.${index}.billable`,
  });

  const watchedCpt = useWatch({
    control,
    name: `selectedStaff.${index}.cpt`,
  });

  useEffect(() => {
    if (watchedSupervision) {
      if (watchedCpt?.includes('97155')) {
        return;
      } else {
        setValue(`selectedStaff.${index}.cpt`, [...watchedCpt, '97155']);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedSupervision]);

  useEffect(() => {
    if (watchedBillable === false) {
      if (watchedCpt?.includes('00000')) {
        return;
      } else {
        setValue(`selectedStaff.${index}.cpt`, [...watchedCpt, '00000']);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedBillable]);

  return (
    <View style={[styles.column, styles.paddingLVertical]}>
      <View
        style={[
          styles.row,
          styles.width,
          styles.justifySpaceBetween,
          styles.alignCenter,
        ]}>
        <View style={[styles.row, styles.alignCenter, styles.marginLRight]}>
          <RHAvatar
            label={avatar}
            color={Colors.RAVEN_BLACK}
            textColor={Colors.RAVEN_WHITE}
            size={32}
          />
          <View style={[styles.marginLLeft]}>
            <Text style={[Typography.P2_MEDIUM, styles.textColorPrimary]}>
              {firstLineDisplay}
            </Text>
            <Text style={[Typography.P3, styles.textColorSecondary]}>
              {user.permission}
            </Text>
          </View>
        </View>
        <TouchableOpacity style={[]} onPress={onClose}>
          <Icon name="close" size={20} />
        </TouchableOpacity>
      </View>
      <View style={[styles.marginLTop]}>
        <InputGroup
          orientation={'tabletContainer'}
          left={<Billable name={`selectedStaff.${index}`} />}
          right={<Supervision name={`selectedStaff.${index}`} />}
        />
      </View>
      <View style={[styles.marginLTop]}>
        {showTime ? (
          <InputGroup
            orientation={'tabletContainer'}
            left={<StartTime name={`selectedStaff.${index}`} />}
            right={<EndTime name={`selectedStaff.${index}`} />}
          />
        ) : null}
      </View>
      <Cpt name={`selectedStaff.${index}`} multiple={true} />
      <ListItemSeparator />
    </View>
  );
};

export default compose(
  withDatabase,
  withObservables(['id'], ({id, database}: any) => ({
    user: database.get(User.table).findAndObserve(id),
  })),
)(UserListItem);
