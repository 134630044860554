export const cptCodes = [
  'cpt97151',
  'cpt97152',
  'cpt97153',
  'cpt97154',
  'cpt97155',
  'cpt97156',
  'cpt97157',
  'cpt97158',
  'cpt0373T',
  'cpt0362T',
  'cptT1024',
  'cpt92507',
  'cpt92508',
  'cpt92526',
  'cpt92609',
  'cpt92522',
  'cpt92523',
  'cpt92610',
  'cptH2019',
  'cptT2024',
  'cptH0032',
  'cpt90791',
  'cpt90837',
  'cptH0031',
  'cptH2012',
  'cptS5111',
  'cptH2014',
  'cptH0046',
  'cpt612',
  'cpt616',
  'cpt805',
];
