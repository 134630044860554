import React, {useEffect, useState} from 'react';
import {InputGroup} from 'src/design-system';
import {Keyboard, Platform, View} from 'react-native';
import _ from 'lodash';
import {RRule} from 'rrule';
import {useStyle} from 'src/providers/style';
import Location from 'src/hook-form-inputs/location';
import DateInput from 'src/hook-form-inputs/date';
import RepeatInterval from 'src/hook-form-inputs/repeat-interval';
import RepeatFrequency from 'src/hook-form-inputs/repeat-frequency';
import RepeatEndMode from 'src/hook-form-inputs/repeat-end-mode';
import RepeatByWeekDay from 'src/hook-form-inputs/repeat-by-week-day';
import StartTime from 'src/hook-form-inputs/start-time';
import EndTime from 'src/hook-form-inputs/end-time';
import HookFormSwitchInput from 'src/hook-form-wrapper/switch-input';
import Client from 'src/hook-form-inputs/client';
import Staff from 'src/hook-form-inputs/staff';
import UserListItem from 'src/modules/scheduling/components/user-list-item';
import ClientListItem from 'src/modules/scheduling/components/client-list-item';
import {useTranslations} from 'src/providers/translation';
import {Separator} from 'src/common-components/atoms';
import {useDimensions} from '@react-native-community/hooks';
import {default as HookDateInput} from 'src/hook-form-wrapper/date-input';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';
import NoteTemplate from 'src/hook-form-inputs/note-template';
import {useFieldArray, useFormContext, useWatch} from 'react-hook-form';
import FormLabel from 'src/design-system/form-label';
import Address from 'src/hook-form-inputs/address';
import AddressAutocompleteInput from 'src/hook-form-wrapper/address-input';

const isWeb = Platform.OS === 'web';
const isAndroid = Platform.OS === 'android';

const AppointmentForm = ({
  appointment,
  repeat,
  repeatFreq,
  ends,
  canEdit,
}: any) => {
  const styles = useStyle();
  const translations = useTranslations();
  const dimensions = useDimensions();
  const [keyboardHeight, setKeyboardHeight] = useState<number>(0);

  useEffect(() => {
    const keyboardWillShowSub = Keyboard.addListener(
      isAndroid ? 'keyboardDidShow' : 'keyboardWillShow',
      keyboardWillShow,
    );
    const keyboardWillHideSub = Keyboard.addListener(
      isAndroid ? 'keyboardDidHide' : 'keyboardWillHide',
      keyboardWillHide,
    );

    return () => {
      keyboardWillShowSub.remove();
      keyboardWillHideSub.remove();
    };
  }, []);

  const keyboardWillShow = event => {
    setKeyboardHeight(event.endCoordinates.height);
  };

  const keyboardWillHide = () => {
    setKeyboardHeight(0);
  };
  const {control, setValue} = useFormContext();

  const {remove: userRemove} = useFieldArray({
    name: 'staff',
    control,
  });
  const watchedAddress = useWatch({
    control,
    name: 'address',
  });
  const location = useWatch({
    control,
    name: 'location',
  });
  const clients = useWatch({
    control,
    name: 'clients',
  });
  const selectedStaff = useWatch({
    control,
    name: 'selectedStaff',
  });

  return (
    <View
      style={[
        styles.padding,
        styles.scrollPadding,
        /* eslint-disable-next-line react-native/no-inline-styles */
        {
          marginBottom: isWeb ? 0 : keyboardHeight,
        },
      ]}>
      <View style={[styles.container]}>
        <View
          style={[
            dimensions.window.width > 768 ? styles.flex : styles.flexGrow,
            styles.column,
          ]}>
          <FormLabel label={'Client(s)'} />
          <Client disabled={!canEdit} />
          {clients.map((item: string, index: number) => (
            <ClientListItem
              key={`client-list-item-${index}`}
              id={item}
              index={index}
              canDelete={canEdit}
              onClose={() => {
                const items = [...clients];
                items.splice(index, 1);
                setValue('clients', items);
              }}
            />
          ))}
        </View>
        <Separator width={20} height={20} />
        <View
          style={[
            dimensions.window.width > 768 ? styles.flex : styles.flexGrow,
            styles.column,
          ]}>
          <FormLabel label={'Staff Member(s)'} />
          <Staff multiple={true} />
          {selectedStaff.map((item: any, index: number) => (
            <UserListItem
              key={`staff-list-item-${item?.id}`}
              id={item.id}
              index={index}
              showTime={selectedStaff.length > 1}
              onClose={() => {
                userRemove(index);
              }}
            />
          ))}
        </View>
      </View>
      <InputGroup
        left={
          <>
            <FormLabel label={'Place of Service'} />
            <Location />
          </>
        }
        right={
          <>
            <FormLabel label={'Location'} />
            <Address
              patientId={
                location === '12' || location === '10' ? clients : undefined
              }
              combineLocations={location === '10' && clients.length !== 0}
            />
          </>
        }
      />
      <InputGroup
        left={<></>}
        right={
          <>
            {watchedAddress === 'other' ? (
              <>
                <FormLabel label={'Custom Address'} />
                <AddressAutocompleteInput
                  name={'customAddress'}
                  label={'Address Line 1'}
                />
              </>
            ) : (
              <></>
            )}
          </>
        }
      />
      <HookFormSwitchInput
        name={'repeat'}
        label={translations('event_repeats')}
      />
      {repeat ? (
        <>
          <InputGroup left={<RepeatInterval />} right={<RepeatFrequency />} />
          {repeatFreq === RRule.WEEKLY.toString() ? <RepeatByWeekDay /> : null}
          <RepeatEndMode />
          <InputGroup
            left={
              <>
                {ends === 'on' ? (
                  <HookDateInput
                    name={'repeatUntil'}
                    label={'Date'}
                    placeholder={'Date'}
                    helper={''}
                    required={false}
                  />
                ) : ends === 'after' ? (
                  <HookFormSelectInput
                    name={'repeatOccurrences'}
                    label={'Occurrences'}
                    items={_.range(1, 100).map((idx: number) => {
                      return {
                        label: idx.toString(),
                        value: idx.toString(),
                      };
                    })}
                  />
                ) : null}
              </>
            }
            right={<></>}
          />
        </>
      ) : null}
      <InputGroup
        left={
          <>
            <FormLabel label={'Appointment Date'} />
            <DateInput />
          </>
        }
        right={
          <>
            <FormLabel label={'Note Template'} />
            <NoteTemplate />
          </>
        }
      />
      <InputGroup
        left={
          <>
            <StartTime date={appointment.start} />
          </>
        }
        right={
          <>
            <EndTime date={appointment.start} />
          </>
        }
      />
    </View>
  );
};

export default AppointmentForm;
