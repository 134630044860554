import React, {useState} from 'react';
import {View} from 'react-native';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {mergeMap} from 'rxjs';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useSelector} from 'react-redux';
import {
  BaseIndexScreen,
  DeleteModal,
  Filters,
  Form,
  Modal,
} from 'src/design-system';
import PatientAuthorizationForm from 'src/modules/patients/components/authorization-form';
import * as Yup from 'yup';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import moment from 'moment';
import {Authorization, Instance} from 'src/models';
import {cptCodes} from 'src/common-utils/cptCodes';
import {useStyle} from 'src/providers/style';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import {Q} from '@nozbe/watermelondb';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslations} from 'src/providers/translation';
import CheckboxArrayInput from 'src/design-system/checkbox-array-input';
import AuthorizationsList from 'src/modules/patients/components/authorizations-list';

export const authorizationStateArray = [{value: 'archived', title: 'Archived'}];

const AuthorizationFilters = ({filter, applyFilters}: any) => {
  const translations = useTranslations();
  const [visible, setVisible] = useState<boolean>(false);
  const validationSchema = Yup.object({});
  const initialValues = {authorizationState: []};

  const notificationNumberCount = () => {
    let result = 0;
    if (filter === undefined) {
      return 0;
    }
    for (const key in filter) {
      result += filter[key].length;
    }
    return result;
  };

  const filterSubmit = (newFilters: any) => {
    applyFilters(newFilters);
    setVisible(false);
  };

  const filterCancelled = () => {
    applyFilters(initialValues);
    setVisible(false);
  };

  const closeMenu = () => {
    setVisible(false);
  };

  return (
    <Filters
      open={visible}
      setOpen={setVisible}
      notificationNumberCount={notificationNumberCount}
      onDismiss={closeMenu}>
      <Form
        handleSubmit={filterSubmit}
        cancelled={filterCancelled}
        initialValues={initialValues}
        validationSchema={validationSchema}
        cancelText={translations('reset')}
        saveText={translations('apply')}>
        <CheckboxArrayInput
          valueKey={'authorizationState'}
          title={translations('state')}
          items={authorizationStateArray}
        />
      </Form>
    </Filters>
  );
};

const Authorizations = ({patient, role, instance}: any) => {
  const database = useDatabase();
  const styles = useStyle();
  const {selectedGroup, userId} = useSelector(state => state.authentication);
  const [submitting, setSubmitting] = useState(false);
  const [edit, setEdit] = useState<any | boolean>(false);
  const [itemId, setItemId] = useState<any | string>('');
  const [showDelete, setShowDelete] = useState<any | boolean>(false);
  const [filters, setFilters] = useState<any>({
    authorizationState: [],
  });

  const initialValues = {
    authorizationNumber: '',
    startDate: new Date(),
    endDate: new Date(),
    payerId: '',
    cpt97151: '0',
    cpt97152: '0',
    cpt97153: '0',
    cpt97154: '0',
    cpt97155: '0',
    cpt97156: '0',
    cpt97157: '0',
    cpt97158: '0',
    cpt0373T: '0',
    cpt0362T: '0',
    cptT1024: '0',
    cpt92507: '0',
    cpt92508: '0',
    cpt92526: '0',
    cpt92609: '0',
    cpt92522: '0',
    cpt92523: '0',
    cpt92610: '0',
    cptH2019: '0',
    cptT2024: '0',
    cptH0032: '0',
    cpt90791: '0',
    cpt90837: '0',
    cptH0031: '0',
    cptH2012: '0',
    cptS5111: '0',
    cptH2014: '0',
    cptH0046: '0',
    cpt612: '0',
    cpt616: '0',
    cpt805: '0',
  };

  const validationSchema = Yup.object({
    authorizationNumber: Yup.string().required(
      'Authorization Number is required',
    ),
    startDate: Yup.date().required('Start Date is required'),
    endDate: Yup.date()
      .required('End Date is required')
      .min(Yup.ref('startDate'), 'End Date must be after Start Date'),
    payerId: Yup.string(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = async (payload: any) => {
    if (edit) {
      let value = {};
      for (const code of cptCodes) {
        value[code] = parseInt(payload[code], 10);
      }
      const entity = await database.get(Authorization.table).find(itemId);
      await entity.updateEntity({
        authorizationNumber: payload.authorizationNumber,
        startDate: payload.startDate,
        endDate: payload.endDate,
        payerId: payload.payerId,
        ...value,
      });
    } else {
      await database.write(async () => {
        await database.get(Authorization.table).create(entity => {
          entity.partition = selectedGroup;
          entity.authorizationNumber = payload.authorizationNumber;
          entity.startDate = payload.startDate;
          entity.endDate = payload.endDate;
          entity.payerId = payload.payerId;
          entity.patientId = patient.id;
          for (const code of cptCodes) {
            entity[code] = parseInt(payload[code], 10);
          }
          entity.patient.id = patient.id;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });
    }
    setSubmitting(false);
    methods.reset(initialValues);
    setEdit(false);
  };

  const handleCancel = () => {
    setSubmitting(!submitting);
    methods.reset(initialValues);
    setEdit(false);
  };
  const deleteCancelled = () => {
    setShowDelete(false);
  };
  const deleteConfirmed = (entity: any) => {
    entity.delete(userId);
    setShowDelete(false);
  };

  const archiveCallback = (authorizationItem: Authorization) => {
    if (authorizationItem?.state !== 'archived') {
      authorizationItem.updateEntity({state: 'archived'});
    } else {
      authorizationItem.updateEntity({state: ''});
    }
  };

  return (
    <BaseIndexScreen
      title={'Authorizations'}
      canAdd={role?.authorizationCreate}
      addButton={
        <RHButton
          onPress={() => {
            setEdit(false);
            methods.reset(initialValues);
            setSubmitting(true);
          }}
          mode={'contained'}
          secondary
          icon="plus">
          ADD AUTHORIZATION
        </RHButton>
      }>
      <Modal
        show={[submitting, setSubmitting]}
        title={edit ? 'Edit Authorization' : 'New Authorization'}>
        <FormProvider {...methods}>
          <PatientAuthorizationForm
            instance={instance}
            handleCancel={handleCancel}
            handleSubmit={methods.handleSubmit(handleSubmit)}
          />
        </FormProvider>
      </Modal>
      <DeleteModal
        model={'Authorization'}
        text={
          showDelete
            ? `${moment(showDelete.startDate).format('YYYY-MM-DD')} to ${moment(
                showDelete.endDate,
              ).format('YYYY-MM-DD')} Authorization`
            : null
        }
        show={[showDelete, setShowDelete]}
        cancelled={() => deleteCancelled()}
        handleSubmit={() => deleteConfirmed(showDelete)}
      />
      <View style={[styles.alignSelfEnd, styles.paddingRight]}>
        <AuthorizationFilters applyFilters={setFilters} filter={filters} />
      </View>
      <AuthorizationsList
        role={role}
        setEdit={setEdit}
        setItemId={setItemId}
        patient={patient}
        setSubmitting={setSubmitting}
        setShowDelete={setShowDelete}
        archiveCallback={archiveCallback}
        methods={methods}
        filter={filters}
      />
    </BaseIndexScreen>
  );
};

export default compose(
  withState,
  withDatabase,
  withObservables([], ({authentication, database}: any) => ({
    instance: database
      ?.get(Instance.table)
      .query(Q.where('_partition', authentication.selectedGroup))
      .observe()
      .pipe(mergeMap(x => x)),
  })),
)(Authorizations);
