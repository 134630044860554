import React, {useEffect, useState} from 'react';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useStyle} from 'src/providers/style';
import {useNavigation} from '@react-navigation/native';
import {BaseIndexScreen, DeleteModal, Modal} from 'src/design-system';
import {FlatList, ScrollView, Text, View} from 'react-native';
import {Typography} from 'src/styles';
import NoResults from 'src/common-components/noResults';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {useSelector} from 'react-redux';
import {Authorization, ProgressReport} from 'src/models';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import Form from 'src/hook-form/form';
import {subtractNMonths} from 'src/common-utils/subtractNMonths';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {Separator} from 'src/common-components/atoms';
import DateInput from 'src/hook-form-wrapper/date-input';
import moment from 'moment';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';
import ProgressReportListItem from '../progress-report-list-item';

const ProgressReports = ({
  incompleteProgressReports,
  submittedProgressReports,
  patient,
  authorizationItems,
}: // role,
any) => {
  const styles = useStyle();
  const database = useDatabase();
  const navigation = useNavigation();
  const {selectedGroup} = useSelector(state => state.authentication);
  const [show, setShow] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<any | boolean>(false);
  const [authorizationItem, setAuthorizationItem] = useState<any>(null);

  const initialValues = {
    startDate: authorizationItem
      ? authorizationItem.startDate
      : subtractNMonths(6),
    endDate: authorizationItem ? authorizationItem.endDate : new Date(),
    authorizationId: authorizationItem ? authorizationItem.id : '',
    type: '',
  };

  const methods = useForm({
    defaultValues: initialValues,
  });

  const watchedAuthId = useWatch({
    control: methods.control,
    name: 'authorizationId',
  });

  const fetchAuthorization = async (id: string) => {
    const fetchedAuthorization = await database
      .get(Authorization.table)
      .find(id);

    if (fetchedAuthorization) {
      setAuthorizationItem(fetchedAuthorization);
      methods.setValue('startDate', fetchedAuthorization?.startDate);
      methods.setValue('endDate', fetchedAuthorization?.endDate);
    }
  };

  useEffect(() => {
    if (watchedAuthId) {
      fetchAuthorization(watchedAuthId);
    } else {
      setAuthorizationItem(null);
      methods.reset({
        ...initialValues,
        authorizationId: '',
        startDate: subtractNMonths(6),
        endDate: new Date(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedAuthId]);

  const handleSubmit = async (values: any) => {
    const createdProgressReport = await database.write(async () => {
      return await database.get(ProgressReport.table).create(entity => {
        entity.partition = selectedGroup;
        entity.submittedAt = null;

        entity.startDate = values.startDate;
        entity.endDate = values.endDate;
        entity.authorizationId = values.authorizationId;
        entity.type = values.type;

        entity.caregiverGuidanceInfoInclude = true;
        entity.backgroundInfoInclude = true;
        entity.medicalInfoInclude = true;
        entity.behaviorInterventionPlanInclude = true;
        entity.summaryInclude = true;
        entity.crisisPlanInclude = true;
        entity.transitionPlanInclude = true;
        entity.recommendationsInclude = true;
        entity.signatureInclude = true;
        entity.caregiverInclude = true;

        entity.cpt97151 = authorizationItem?.cpt97151 || 0;
        entity.cpt97152 = authorizationItem?.cpt97152 || 0;
        entity.cpt97153 = authorizationItem?.cpt97153 || 0;
        entity.cpt97154 = authorizationItem?.cpt97154 || 0;
        entity.cpt97155 = authorizationItem?.cpt97155 || 0;
        entity.cpt97156 = authorizationItem?.cpt97156 || 0;
        entity.cpt97157 = authorizationItem?.cpt97157 || 0;
        entity.cpt97158 = authorizationItem?.cpt97158 || 0;
        entity.cpt0373T = authorizationItem?.cpt0373T || 0;
        entity.cpt0362T = authorizationItem?.cpt0362T || 0;
        entity.cptT1024 = authorizationItem?.cptT1024 || 0;
        entity.cpt92507 = authorizationItem?.cpt92507 || 0;
        entity.cpt92508 = authorizationItem?.cpt92508 || 0;
        entity.cpt92526 = authorizationItem?.cpt92526 || 0;
        entity.cpt92609 = authorizationItem?.cpt92609 || 0;
        entity.cpt92522 = authorizationItem?.cpt92522 || 0;
        entity.cpt92523 = authorizationItem?.cpt92523 || 0;
        entity.cpt92610 = authorizationItem?.cpt92610 || 0;
        entity.cptH2019 = authorizationItem?.cptH2019 || 0;
        entity.cptT2024 = authorizationItem?.cptT2024 || 0;
        entity.cptH0032 = authorizationItem?.cptH0032 || 0;
        entity.cpt90791 = authorizationItem?.cpt90791 || 0;
        entity.cpt90837 = authorizationItem?.cpt90837 || 0;
        entity.cptH0031 = authorizationItem?.cptH0031 || 0;
        entity.cptH2012 = authorizationItem?.cptH2012 || 0;
        entity.cptS5111 = authorizationItem?.cptS5111 || 0;
        entity.cptH2014 = authorizationItem?.cptH2014 || 0;
        entity.cptH0046 = authorizationItem?.cptH0046 || 0;
        entity.cpt612 = authorizationItem?.cpt612 || 0;
        entity.cpt616 = authorizationItem?.cpt616 || 0;
        entity.cpt805 = authorizationItem?.cpt805 || 0;

        entity.patientId = patient.id;
      });
    });
    navigation.navigate('ProgressReportScreen', {
      id: createdProgressReport.id,
    });
  };

  const confirmCancel = () => {
    setAuthorizationItem(null);
    methods.reset({
      ...initialValues,
      authorizationId: '',
      startDate: subtractNMonths(6),
      endDate: new Date(),
    });
    setShow(false);
  };

  const deleteCancelled = () => {
    setShowDelete(false);
  };
  const deleteConfirmed = (entity: any) => {
    entity.delete();
    setShowDelete(false);
  };

  return (
    <BaseIndexScreen
      title={'Progress Reports'}
      canAdd={true}
      addButton={
        // TODO: ADD PERMISSIONS BEFORE RELEASE
        // role?.progressReportCreate ? (
        //   <RHButton onPress={() => setShow(true)} mode={'contained'} secondary>
        //     START PROGRESS REPORT
        //     <Icon name="plus" size={16} style={styles.marginMLeft} />
        //   </RHButton>
        // ) : (
        //   <></>
        // )
        <RHButton onPress={() => setShow(true)} mode={'contained'} secondary>
          START PROGRESS REPORT
          <Icon name="plus" size={16} style={styles.marginMLeft} />
        </RHButton>
      }>
      <DeleteModal
        model={'Progress Report'}
        text={
          showDelete
            ? `${moment(showDelete.startDate).format('YYYY-MM-DD')} to ${moment(
                showDelete.endDate,
              ).format('YYYY-MM-DD')} Progress Report`
            : null
        }
        show={[showDelete, setShowDelete]}
        cancelled={() => deleteCancelled()}
        handleSubmit={() => deleteConfirmed(showDelete)}
      />
      <ScrollView style={[styles.flex]}>
        <Modal title={'New Progress Report'} show={[show, setShow]}>
          <FormProvider {...methods}>
            <Form
              handleSubmit={methods.handleSubmit(handleSubmit)}
              cancelled={() => confirmCancel()}
              cancelText={'Cancel'}
              saveText={'Continue'}>
              <View style={[styles.paddingVertical]}>
                <View style={[styles.row, styles.paddingBottom]}>
                  <SafeAreaProvider>
                    <DateInput
                      name={'startDate'}
                      label={'Start Date'}
                      placeholder={'Start Date'}
                      showHelper={false}
                      required={false}
                    />
                  </SafeAreaProvider>
                  <Separator width={50} />
                  <SafeAreaProvider>
                    <DateInput
                      name={'endDate'}
                      label={'End Date'}
                      placeholder={'End Date'}
                      showHelper={false}
                      required={false}
                    />
                  </SafeAreaProvider>
                </View>
                <View style={[styles.flex]}>
                  <HookFormSelectInput
                    canCancel={true}
                    name={'authorizationId'}
                    items={authorizationItems}
                    label={'Prior Authorization (optional)'}
                    placeholder={'Prior Authorization (optional)'}
                    helper={''}
                    searchable={false}
                  />
                </View>
              </View>
            </Form>
          </FormProvider>
        </Modal>
        {incompleteProgressReports.length ? (
          <>
            <Text style={[styles.padding, Typography.H4]}>
              Reports In Progress
            </Text>
            <FlatList
              scrollEnabled={true}
              data={[...incompleteProgressReports].sort(
                (a: any, b: any) => a.startDate - b.startDate,
              )}
              keyExtractor={item => item.id}
              renderItem={({item}: any) => (
                <ProgressReportListItem
                  progressReport={item}
                  inProgress={true}
                  // TODO: ADD PERMISSIONS BEFORE RELEASE
                  // canEdit={role?.progressReportEdit}
                  // canDelete={role?.progressReportDelete}
                  canEdit={true}
                  canDelete={true}
                  editSelected={() => {
                    navigation.navigate('ProgressReportScreen', {
                      id: item.id,
                    });
                  }}
                  deleteSelected={() => {
                    setShowDelete(item);
                  }}
                />
              )}
            />
          </>
        ) : (
          <></>
        )}
        {submittedProgressReports.length === 0 ? (
          <NoResults message={'Client has no completed Progress Reports.'} />
        ) : (
          <>
            <Text style={[styles.padding, Typography.H4]}>
              Completed Reports
            </Text>
            <FlatList
              scrollEnabled={true}
              data={[...submittedProgressReports].sort(
                (a: any, b: any) => a.startDate - b.startDate,
              )}
              keyExtractor={item => item.id}
              renderItem={({item}: any) => (
                <ProgressReportListItem
                  progressReport={item}
                  inProgress={false}
                  // TODO: ADD PERMISSIONS BEFORE RELEASE
                  // canEdit={role?.progressReportEdit}
                  // canDelete={role?.progressReportDelete}
                  canEdit={true}
                  canDelete={true}
                  viewSelected={() => {
                    navigation.navigate('ProgressReportRecapScreen', {
                      id: item.id,
                    });
                  }}
                  editSelected={() => {
                    navigation.navigate('ProgressReportScreen', {
                      id: item.id,
                    });
                  }}
                  deleteSelected={() => {
                    setShowDelete(item);
                  }}
                />
              )}
            />
          </>
        )}
      </ScrollView>
    </BaseIndexScreen>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(
    ['patient', 'authentication'],
    ({patient, database, authentication}: any) => ({
      submittedProgressReports: patient?.submittedProgressReports ?? of([]),
      incompleteProgressReports: patient?.incompleteProgressReports ?? of([]),
      authorizations: database
        .get(Authorization.table)
        .query(
          Q.where('patient_id', patient.id),
          Q.where('_partition', authentication.selectedGroup),
          Q.where('deleted_at', null),
          Q.sortBy('end_date', Q.desc),
        ),
    }),
  ),
  withObservables(['authorizations'], ({authorizations}: any) => ({
    authorizationItems: of(
      authorizations.map((authorization: Authorization) => ({
        value: authorization.id,
        label: `${authorization.authorizationNumber}, ${moment(
          authorization.startDate,
        ).format('L')}-${moment(authorization.endDate).format('L')}`,
      })),
    ),
  })),
)(ProgressReports);
