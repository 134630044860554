import React, {useState} from 'react';
import {ScrollView, View} from 'react-native';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import Stepper from 'src/design-system/stepper';
import {useStyle} from 'src/providers/style';
import {Colors} from 'src/styles';
import {useForm, FormProvider} from 'react-hook-form';
import {useNavigation} from '@react-navigation/native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {
  Assessments,
  Background,
  BehaviorGoals,
  Caregiver,
  ClientInfo,
  Goals,
  Review,
  SkillGoals,
  Summary,
} from './components';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {getAge} from 'src/common-utils/age';
import {ProgressReport} from 'src/models';
import {replace} from 'src/navigation/utils/replace';

const ProgressReportFormScreen = ({
  patient,
  diagnoses,
  progressReport,
  progressReportCaregivers,
  signatures,
}: any) => {
  const navigation = useNavigation();
  const styles = useStyle();
  const [currentStep, setCurrentStep] = useState<number>(0);

  const methods = useForm({
    defaultValues: {
      clientInfo: {
        clientName: `${patient.firstName} ${patient.lastName}`,
        birthDate: patient.birthDate,
        clientAge: getAge(patient.birthDate),
        memberId: '',
        diagnosis: diagnoses.map(diagnosis => diagnosis.diagnosis).join(', '),
        caregiver: progressReportCaregivers.length
          ? progressReportCaregivers.map(
              progressReportCaregiver => progressReportCaregiver.caregiverId,
            )
          : [],
      },
      background: {
        background_info: progressReport.backgroundInfo || '',
        medical_info: progressReport.medicalInfo || '',
        background_info_include: progressReport.backgroundInfoInclude,
        medical_info_include: progressReport.medicalInfoInclude,
      },
      signatures: signatures.length
        ? signatures.map(signature => {
            return {
              _id: signature?.id,
              entity: signature.entity,
              entity_id: signature.entity_id,
              signature: signature.signature,
              type: signature.type,
              signatory: signature.signatory,
              updatedAt: signature.updatedAt,
              show: false,
            };
          })
        : [
            {
              entity: '',
              entity_id: '',
              signature: '',
              type: 'staff',
              signatory: '',
              updatedAt: new Date(),
            },
          ],
      summary: {
        overview: progressReport.summary || '',
        crisis_plan: progressReport.crisisPlan || '',
        transition_plan: progressReport.transitionPlan || '',
        recommendations: progressReport.recommendations || '',
        overview_include: progressReport.summaryInclude,
        crisis_plan_include: progressReport.crisisPlanInclude,
        transition_plan_include: progressReport.transitionPlanInclude,
        recommendations_include: progressReport.recommendationsInclude,
        signature_include: progressReport.signatureInclude,
        startDate:
          progressReport.startDate > 0 ? progressReport.startDate : new Date(),
        endDate:
          progressReport.endDate > 0 ? progressReport.endDate : new Date(),
        cpt97151: progressReport.cpt97151,
        cpt97152: progressReport.cpt97152,
        cpt97153: progressReport.cpt97153,
        cpt97154: progressReport.cpt97154,
        cpt97155: progressReport.cpt97155,
        cpt97156: progressReport.cpt97156,
        cpt97157: progressReport.cpt97157,
        cpt97158: progressReport.cpt97158,
        cpt0373T: progressReport.cpt0373T,
        cpt0362T: progressReport.cpt0362T,
        cptT1024: progressReport.cptT1024,
        cpt92507: progressReport.cpt92507,
        cpt92508: progressReport.cpt92508,
        cpt92526: progressReport.cpt92526,
        cpt92609: progressReport.cpt92609,
        cpt92522: progressReport.cpt92522,
        cpt92523: progressReport.cpt92523,
        cpt92610: progressReport.cpt92610,
        cptH2019: progressReport.cptH2019,
        cptT2024: progressReport.cptT2024,
        cptH0032: progressReport.cptH0032,
        cpt90791: progressReport.cpt90791,
        cpt90837: progressReport.cpt90837,
        cptH0031: progressReport.cptH0031,
        cptH2012: progressReport.cptH2012,
        cptS5111: progressReport.cptS5111,
        cptH2014: progressReport.cptH2014,
        cptH0046: progressReport.cptH0046,
        cpt612: progressReport.cpt612,
        cpt616: progressReport.cpt616,
        cpt805: progressReport.cpt805,
      },
      goals: {
        behavior_intervention_plan:
          progressReport.behaviorInterventionPlan || '',
        behavior_intervention_plan_include:
          progressReport.behaviorInterventionPlanInclude,
      },
      caregiver: {
        caregiver_guidance: progressReport.caregiverGuidanceInfo || '',
        caregiver_guidance_include: progressReport.caregiverGuidanceInfoInclude,
      },
      startDate:
        progressReport.startDate > 0 ? progressReport.startDate : new Date(),
      endDate: progressReport.endDate > 0 ? progressReport.endDate : new Date(),
    },
  });

  const StepperOnClickCallback = (proceedStep: number) => {
    setCurrentStep(proceedStep);
  };

  const navigateBack = () => {
    navigation.navigate('PatientProfile', {
      screen: 'Progress Reports',
      patientId: patient.id,
    });
  };

  const onSubmit = async () => {
    progressReport.updateEntity({
      submittedAt: new Date(),
    });

    navigation.dispatch(
      replace('ProgressReportRecapScreen', {
        id: progressReport.id,
        block: true,
      }),
    );
  };

  const steps = [
    {
      step: 'Client Info',
      form: ClientInfo,
    },
    {
      step: 'Background',
      form: Background,
    },
    {
      step: 'Assessments',
      form: Assessments,
    },
    {
      step: 'Goals',
      form: Goals,
    },
    {
      step: 'Behavior Goals',
      form: BehaviorGoals,
    },
    {
      step: 'Skill Goals',
      form: SkillGoals,
    },
    {
      step: 'Caregiver',
      form: Caregiver,
    },
    {
      step: 'Summary',
      form: Summary,
    },
    {
      step: 'Review',
      form: Review,
    },
  ];

  const FormPart = steps[currentStep].form;

  return (
    <View style={[styles.flex, styles.alignItems]}>
      <ScrollView
        style={[styles.flex]}
        // eslint-disable-next-line react-native/no-inline-styles
        contentContainerStyle={{marginBottom: 100}}>
        <View style={styles.paddingHorizontal}>
          <Stepper
            currentStep={[currentStep, setCurrentStep]}
            steps={steps}
            onClickCallback={StepperOnClickCallback}
          />
        </View>
        <FormProvider {...methods}>
          <FormPart
            key={steps[currentStep].step}
            patient={patient}
            diagnoses={diagnoses}
            values={methods.getValues()}
            progressReportId={progressReport.id}
            progressReport={progressReport}
            shouldShowMenu={false}
          />
        </FormProvider>
      </ScrollView>
      <View
        style={[
          styles.width,
          styles.padding,
          styles.positionAbsolute,
          styles.backgroundColorWhite,
          // eslint-disable-next-line react-native/no-inline-styles
          {bottom: 0},
        ]}>
        <View style={[styles.justifySpaceBetween, styles.row]}>
          <RHButton
            style={styles.paddingHorizontal}
            mode="outlined"
            textColor={Colors.RAVEN_BLACK}
            onPress={navigateBack}>
            CANCEL
          </RHButton>
          <View
            style={[
              styles.justifyCenter,
              styles.alignCenter,
              styles.margin0Bottom,
            ]}>
            <RHButton
              style={styles.paddingHorizontal}
              mode="contained"
              onPress={() => {
                currentStep !== steps.length - 1
                  ? setCurrentStep(prevStep => prevStep + 1)
                  : methods.handleSubmit(onSubmit)();
              }}>
              {currentStep !== steps.length - 1 ? 'NEXT' : 'FINISH'}
              <Icon name="arrow-right" size={16} style={styles.marginMLeft} />
            </RHButton>
          </View>
        </View>
      </View>
    </View>
  );
};

export default compose(
  withDatabase,
  withObservables(['route'], ({route, database}: any) => ({
    progressReport: database
      .get(ProgressReport.table)
      .findAndObserve(route?.params?.id),
  })),
  withObservables(['progressReport'], ({progressReport}: any) => ({
    patient: progressReport.patient,
    progressReportCaregivers: progressReport.progressReportCaregivers,
    signatures: progressReport.progressReportSignatures,
  })),
  withObservables([], ({patient}: any) => ({
    diagnoses: patient.activeDiagnoses,
  })),
)(ProgressReportFormScreen);
